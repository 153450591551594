import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  DetailsBox,
  Heading,
  SubHeading,
} from "../../../styled/styledTypography";
import ManageTable from "./Table";
import api from "../../../../misc/api";
import { useNavigate } from "react-router-dom";
import { CurrencyPound, Search } from "@mui/icons-material";
import moment from "moment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TITLE_PREFIX } from "../../../../misc/constant";

function Manage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [filter, setFilter] = useState(4);
  const [scheduleDialog, setScheduleDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [quoteDialog, setQuoteDialog] = useState(false);
  const [selectedIds, setSelectedIds] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [alert, setAlert] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [tableKey, setTableKey] = useState(1);
  const [sendEmail, setSendEmail] = useState(false);

  useEffect(() => setTableKey(Math.floor(Math.random() * 9999)), [data]);

  useEffect(() => {
    document.title = TITLE_PREFIX + "Manage Collections";
  }, []);

  useEffect(() => console.log(tableKey), [tableKey]);

  const navigate = useNavigate();

  const getData = (status = filter) => {
    setLoading(true);
    console.log(page + " - " + status);
    api
      .post(`/collections/all?page=${page + 1}&per_page=${rowsPerPage}`, {
        status,
      })
      .then((response) => {
        setData([...response.data.data]);
        setTotalPages(response.data.pagination.total_pages);
        setTotalItems(response.data.pagination.total_items);
      })
      .catch((e) => {
        setData([]);
        console.log("error", e);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  const handleSearch = () => {
    setFilter(4);
    setLoading(true);
    api
      .post("/collections/search", {
        searchQuery,
      })
      .then((res) => {
        const _data = res.data.data;
        const totalItems = _data.length;
        setData(_data);
        setPage(1);
        setRowsPerPage(rowsPerPage);
        setTotalItems(totalItems);
      })
      .catch((e) => {
        setData([]);
        console.log("error", e);
      })
      .finally(() => setLoading(false));
  };

  const navigateDetails = (id) => {
    navigate(`/dashboard/collections/${id}`);
  };

  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);

  const findCollection = (id) => data?.find((_c) => _c._id === id);

  const scheduleSelected = (id) => {
    const _collection = findCollection(id);
    if (!_collection) {
      // show alert msg NOT FOUND
    } else {
      setSelectedCollection(_collection);
      setScheduleDialog(true);
    }
  };

  const handleSchedule = () => {
    api
      .post("/collections/schedule", {
        id: selectedCollection?._id,
        date: selectedCollection.scheduledOn,
      })
      .then((res) => {
        if (res.data?.success) {
          const selected = res.data.data;
          const _data = data.map((_collection) => {
            if (_collection._id === selected._id) {
              _collection.scheduledOn = selected.scheduledOn;
              _collection.status = selected.status;
            }
            return _collection;
          });

          setData(_data);
          setAlert({
            status: true,
            msg:
              selected?.ref +
              " has been scheduled for " +
              moment(selected.scheduledOn).format("DD / MM / YYYY"),
            color: "success",
          });
        }
      })
      .catch((e) => {
        console.log("error while confirming");
      })
      .finally((_) => setScheduleDialog(false));
  };

  const deleteSelected = (data) => {
    if (typeof data === "string") {
      setSelectedIds([data]);
    } else {
      setSelectedIds(data);
    }
    setDeleteDialog(true);
  };

  const handleDelete = () => {
    api
      .post("/collections/deleteMultiple", { ids: selectedIds })
      .then((res) => {
        // console.log(res);
        setSelectedIds(null);
        getData();
      })
      .catch((e) => console.log(e));
    setDeleteDialog(false);
    setAlert({
      status: true,
      msg: "Deleted Successfully!",
      color: "success",
    });
  };

  const handleShowOnlyChange = (e) => {
    // "e.target.value < 4" = because all's value is 4
    const _filter = e.target.value < 8 ? e.target.value : null;
    setFilter(e.target.value);
    setSearchQuery(null);
    setPage(0);
    getData(_filter);
  };

  const handleSendQuote = (id) => {
    const _collection = findCollection(id);

    if (!_collection) {
      // show alert msg NOT FOUND
    } else {
      setSelectedCollection(_collection);
      setQuoteDialog(true);
    }
  };

  const handleQuote = async () => {
    await api
      .post("/collections/send-quote", { ...selectedCollection, sendEmail })
      .then((res) => {
        if (res.data?.success) {
          const selected = res.data.data;
          const _data = data.map((_collection) => {
            if (_collection._id === selected._id) {
              _collection.fee = selected.fee;
              _collection.status = selected.status;
            }
            return _collection;
          });
          setData(_data);
          setAlert({
            status: true,
            msg: `A quote of £${_data?.fee} has been sent to ${_data.email}`,
            color: "success",
          });
        }
      })
      .catch((e) => {
        console.log("error while confirming");
        console.log(e);
      })
      .finally((_) => setQuoteDialog(false));
  };

  const handleBooking = (id) => {
    navigate(`/dashboard/collections/${id}`, {
      state: {
        id: id,
        convert_to_booking: true,
      },
    });
  };

  return (
    <Box>
      <Snackbar
        open={alert?.status}
        autoHideDuration={5000}
        onClose={() => setAlert(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={alert?.color}>{alert?.msg}</Alert>
      </Snackbar>

      <Dialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        aria-labelledby="delete-dialog"
        aria-describedby="delete-dailog-description"
        fullWidth
      >
        <DialogTitle id="delete-dialog">{"Delete Collection(s)"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dailog-description">
            Are you sure you want to proceed?
            <Typography color={"error"} mt={0}>
              This action is irreversiable.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="success"
            onClick={() => setDeleteDialog(false)}
            autoFocus
          >
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={handleDelete}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* SCHEDULE COLLECTION */}
      <Dialog
        open={scheduleDialog}
        onClose={() => setScheduleDialog(false)}
        fullWidth
      >
        <DialogTitle id="schedule-dialog">
          <Heading>Schedule Collection</Heading>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item md={6}>
              <DetailsBox>
                <Box>
                  <SubHeading>Company Name</SubHeading>
                  <Box>{selectedCollection?.companyName}</Box>
                </Box>
                <Box>
                  <SubHeading>Customer Phone</SubHeading>
                  <Box>{selectedCollection?.contactNo}</Box>
                </Box>
              </DetailsBox>
            </Grid>
            <Grid item md={6}>
              <DetailsBox>
                <Box>
                  <SubHeading>Booked On</SubHeading>
                  <Box>
                    {moment(selectedCollection?.bookedOn).format(
                      "DD / MM / YYYY"
                    )}
                  </Box>
                </Box>
                <Box>
                  <SubHeading>Scheduled For</SubHeading>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          format="DD-MM-YYYY"
                          value={selectedCollection?.scheduledOn}
                          onChange={(val) =>
                            setSelectedCollection({
                              ...selectedCollection,
                              scheduledOn: val,
                            })
                          }
                          label="Schedule Date"
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </Box>
              </DetailsBox>
            </Grid>
          </Grid>
          <DialogContentText
            color={"error"}
            sx={{ mt: 5 }}
            id="schedule-dailog-description"
          >
            A confirmation email will be sent to {selectedCollection?.email}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="error"
            onClick={() => setScheduleDialog(false)}
            autoFocus
          >
            Cancel
          </Button>
          <Button variant="contained" color="success" onClick={handleSchedule}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* SEND QUOTE */}

      <Dialog
        open={quoteDialog}
        onClose={() => setQuoteDialog(false)}
        fullWidth
        maxWidth={"lg"}
      >
        <DialogTitle id="schedule-dialog">
          <Heading textAlign={"center"}>Send Quote to Customer</Heading>
        </DialogTitle>
        <DialogContent>
          <Divider sx={{ mb: 2 }} />
          <Grid container>
            <Grid item md={6}>
              <DetailsBox>
                <Box>
                  <SubHeading>Company Name</SubHeading>
                  <Box>{selectedCollection?.companyName}</Box>
                </Box>
                <Box>
                  <SubHeading>Name</SubHeading>
                  <Box>{selectedCollection?.contactName || " - "}</Box>
                </Box>
                <Box>
                  <SubHeading>Customer Phone</SubHeading>
                  <Box>{selectedCollection?.contactNo || " - "}</Box>
                </Box>
              </DetailsBox>
            </Grid>
            <Grid item md={6}>
              <DetailsBox>
                <Box>
                  <SubHeading>Enquired on </SubHeading>
                  <Box>
                    {moment(selectedCollection?.createdOn).format(
                      "DD / MM / YYYY"
                    )}
                  </Box>
                </Box>
                <Box>
                  <SubHeading>Email</SubHeading>
                  <Box>{selectedCollection?.email || " - "}</Box>
                </Box>
              </DetailsBox>
            </Grid>
            <Grid item md={12}>
              <Divider
                sx={{
                  mt: 3,
                  mb: 1,
                }}
              />
            </Grid>
            <Grid item md={12}>
              <Box>
                <SubHeading my={1}>Customer's Comments </SubHeading>
                <Box pl={1}>{selectedCollection?.enquiryComment}</Box>
                {selectedCollection?.enquiryAttachmentLink && (
                  <Box my={3}>
                    <a
                      style={{
                        color: "blue",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                      rel="noreferrer"
                      target="_blank"
                      href={selectedCollection?.enquiryAttachmentLink}
                    >
                      Click to Open Attachment
                    </a>
                  </Box>
                )}
              </Box>
              <Divider sx={{ my: 3 }} />
            </Grid>

            <Grid item md={12}>
              <SubHeading mb={2}>Questions / Comments to send</SubHeading>
              <TextField
                multiline
                name={"enquiryQuestions"}
                value={selectedCollection?.enquiryQuestions}
                placeholder="Anything you write here will be sent to customer"
                onChange={(e) =>
                  setSelectedCollection({
                    ...selectedCollection,
                    enquiryQuestions: e.target.value,
                  })
                }
                fullWidth
                minRows={4}
              />
            </Grid>
            <Grid item md={2}>
              <SubHeading sx={{ my: 2 }}>Fee</SubHeading>
              <TextField
                id="fee"
                name="fee"
                size="small"
                type="number"
                onChange={(e) =>
                  setSelectedCollection({
                    ...selectedCollection,
                    fee: e.target.value,
                  })
                }
                value={data?.fee}
                fullWidth
                step={"0.01"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CurrencyPound />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  mb: 2,
                }}
              />
            </Grid>
          </Grid>
          <DialogContentText
            color={"error"}
            sx={{ mt: 5 }}
            id="schedule-dailog-description"
          >
            <Checkbox
              checked={sendEmail}
              onChange={(e) => setSendEmail(e.target.checked)}
            />{" "}
            An email will be sent to {selectedCollection?.email}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="error"
            onClick={() => setQuoteDialog(false)}
            autoFocus
          >
            Cancel
          </Button>
          <Button variant="contained" color="success" onClick={handleQuote}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          m: 4,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Heading>Manage Collections</Heading>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            my: 2,
          }}
        >
          <Box>
            <FormControl>
              <RadioGroup
                onChange={handleShowOnlyChange}
                value={filter}
                name="collection-status"
                row
              >
                <FormControlLabel value="4" control={<Radio />} label="All" />
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="Booked / Waiting"
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Scheduled"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="Collected"
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="Invoiced"
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="Invoice Overdue"
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="Enquiry"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box
            sx={{
              width: "50%",
              px: 1,
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          >
            <Paper
              sx={{
                px: "2px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
              key={filter}
              elevation={0}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Reference / Email / Post Code / Customer Name / Company"
                fullWidth
                value={searchQuery}
                onKeyUp={(e) => e.keyCode === 13 && handleSearch()}
                onChange={(e) => setSearchQuery(e.target.value)}
                label="Search"
                inputProps={{
                  "aria-label":
                    "Reference / Email / Post Code / Customer Name / Company",
                }}
              />
              <IconButton
                onClick={() => handleSearch()}
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
              >
                <Search />
              </IconButton>
            </Paper>
          </Box>
        </Box>
        <Box key={tableKey}>
          <ManageTable
            loading={loading}
            rows={data}
            bookingAction={handleBooking}
            scheduleAction={scheduleSelected}
            openDetails={navigateDetails}
            deleteAction={deleteSelected}
            handleSendQuote={handleSendQuote}
            page={page}
            totalPages={totalPages}
            totalItems={totalItems}
            rowsPerPage={rowsPerPage}
            onPageChange={(newPage) => setPage(newPage)}
            onRowsPerPageChange={(newRowsPerPage) => {
              setRowsPerPage(newRowsPerPage);
              setPage(0);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Manage;
